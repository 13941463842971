@import '../../assets/styles/variables.scss';
#pagePago {
    padding-top: 20px;
    padding-bottom: 20px;
    &.disabled{
        opacity: .3;
        pointer-events: none;
    }
    .back {
        cursor: pointer;
        display: block;
    }
    .datos {
        h2 {
            line-height: 22px;
            padding: 20px 0;
            border-bottom: solid 1px $placeholder;
        }
        .inputCode {
            margin-bottom: 40px;
        }
        .loginComponent {
            label {
                margin-bottom: 10px;
            }
            button {
                margin-top: 10px;
            }
        }
        .datosUser {
            margin-top: 0px;
            p {
                margin-bottom: 10px;
                font-size: 14px;
                line-height: 14px;
                &:first-child {
                    font-size: 18px;
                    font-weight: bold;
                    line-height: 18px;
                }
                span.editarTelefono {
                    text-decoration: underline;
                    margin-left: 10px;
                    cursor: pointer;
                }
            }
            button {
                margin-top: 40px;
                width: auto;
            }
        }
        .entrega {
            margin-top: 20px;
            margin-bottom: 40px;
            .tipos {
                margin: 0 -10px;
                .tipo {
                    background: #fff;
                    width: 210px;
                    height: 80px;
                    color: $color2;
                    border: solid 1px #bebebe;
                    border-radius: 8px;
                    padding: 5px 10px;
                    margin-bottom: 25px;
                    margin-right: 10px;
                    margin-left: 10px;
                    &.active {
                        color: $color6;
                        border-color: #bebebe;
                    }
                    cursor: pointer;
                    i {
                        font-size: 24px;
                    }
                    p {
                        font-size: 14px;
                        width: 100%;
                        margin-bottom: 0;
                        text-align: center;
                    }
                }
            }
            .item-dir {
                box-shadow: $shadow;
                background: #fff;
                border-radius: 5px;
                display: block;
                width: 100%;
                padding: 20px;
                margin-bottom: 30px;
                cursor: pointer;
                transition: ease-in-out all 0.2s;
                padding-right: 50px;
                position: relative;
                .name {
                    color: $color1;
                    font-size: 16px;
                    margin-bottom: 10px;
                }
                span {
                    color: $color2;
                    font-size: 14px;
                }
                span.ref {
                    color: $color3;
                }
                &:hover {
                    box-shadow: 0 0 20px rgba(139, 158, 167, 0.25);
                }
                &:before {
                    content: '';
                    display: block;
                    width: 17px;
                    height: 17px;
                    border-radius: 50%;
                    border: solid 1px $color7;
                    position: absolute;
                    right: 20px;
                    top: 50%;
                    transform: translateY(-50%);
                }
                &.selected:after {
                    content: '';
                    display: block;
                    width: 11px;
                    height: 11px;
                    border-radius: 50%;
                    background: $color6;
                    position: absolute;
                    right: 23px;
                    top: 50%;
                    transform: translateY(-50%);
                }
            }
            .notaEntrega {
                font-size: 14px;
                margin-top: 20px;
                padding: 20px 0;
                border-top: solid 1px $placeholder;
            }
            .wrapMap {
                border: solid 1px #bebebe;
                width: 100%;
                height: 300px;
                position: relative;
                border-radius: 8px;
                overflow: hidden;
            }
        }
        .wrapUsuario {
            box-shadow: $shadow;
            background: #fff;
            border-radius: 12px;
            overflow: hidden;
            margin-top: 10px;
            padding: 20px;
        }
    }

    .compra {
        width: 100%;
        .top {
            border-bottom: solid 1px $placeholder;
            margin-top: 14px;
            h2 {
                padding: 20px;
                margin-bottom: 0;
            }
        }
        .carrito {
            box-shadow: $shadow;
            background: #fff;
            border-radius: 12px;
            overflow: hidden;
            margin-top: 10px;
            .detalle {
                padding: 0 25px;
                .productos {
                    .itemCar {
                        padding: 15px 0;
                        &:not(:last-child) {
                            border-bottom: solid 1px $placeholder;
                        }
                        figure {
                            margin-right: 10px;
                            img {
                                display: block;
                                width: 48px;
                                border-radius: 6px;
                            }
                            margin-bottom: 0;
                        }
                        .nombre {
                            margin-bottom: 0;
                            font-size: 14px;
                            font-weight: 600;
                            line-height: 18px;
                            color: $color1;
                        }
                        .descripcion {
                            font-size: 12px;

                            line-height: 14px;
                            color: $color3;
                        }
                        .icon-trash {
                            cursor: pointer;
                        }
                        .controls {
                            i {
                                font-size: 18px;
                                line-height: 18px;
                                height: 18px;
                                cursor: pointer;
                            }
                            span {
                                line-height: 18px;
                                font-size: 14px;
                                font-weight: 600;
                                padding: 0 10px;
                            }
                        }
                        & > div {
                            &:first-child {
                                width: 60%;
                                display: flex;
                                align-items: center;
                            }
                            &:last-child {
                                width: 40%;
                            }
                        }
                    }
                }
                .montos {
                    border-top: solid 1px $placeholder;
                    border-bottom: solid 1px $placeholder;
                    padding: 15px 0;
                    span {
                        font-size: 14px;
                    }
                }
                .total {
                    font-size: 22px;
                    font-family: R-B, arial;
                    line-height: 22px;
                    padding: 20px 0;
                    margin-bottom: 0;
                }
                .cambiarMoneda {
                    font-size: 12px;
                    color: var(--colorPpal);
                    text-decoration: underline;
                    margin-bottom: 20px;
                    cursor: pointer;
                }
            }
        }
    }

    .metodosWrap {
        box-shadow: $shadow;
        background: #fff;
        border-radius: 12px;
        overflow: hidden;
        margin-top: 60px;
        min-height: 600px;
        padding: 20px;
        .top {
            border-bottom: solid 1px $placeholder;
            margin-bottom: 20px;
            h2 {
                line-height: 22px;
                padding: 20px;
                margin-bottom: 0;
            }
        }

        .metodos {
            padding: 0 20px;
            .met {
                width: 120px;
                height: 80px;
                color: $color2;
                background: #fff;
                border: solid 1px $color2;
                border-radius: 8px;
                padding: 5px 10px;
                margin-bottom: 25px;
                margin-right: 15px;
                cursor: pointer;
            }
            button {
                width: 100%;
                margin-bottom: 25px;
            }
        }
    }
}

#modalMoneda {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    overflow: auto;
    z-index: 1000;
    .overlay {
        position: fixed;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background: rgba(0, 0, 0, 0.7);
        z-index: -1;
    }
    .wrapMonedas {
        position: relative;
        min-height: 100%;
        width: 100%;
        max-width: 380px;
        margin: 0 auto;
        display: flex;
        align-items: center;
        .options {
            width: 100%;
            min-height: 160px;
            border-radius: 12px;
            background: #fff;
            position: relative;
            display: flex;
            align-items: stretch;
            ul {
                list-style: none;
                padding: 0;
                margin-bottom: 0;
                width: 100%;
                li {
                    font-size: 16px;
                    padding: 0 35px;
                    p {
                        font-size: 16px;

                        line-height: 16px;
                        position: relative;
                        padding: 24px 0;
                        cursor: pointer;
                    }
                    &.active p:before {
                        content: '';
                        display: block;
                        height: 12px;
                        width: 12px;
                        border-radius: 50%;
                        background: #4dca5b;
                        position: absolute;
                        left: -25px;
                        top: 50%;
                        transform: translateY(-50%);
                    }
                    &:not(:last-child) {
                        p {
                            border-bottom: solid 1px $placeholder;
                        }
                    }
                }
            }
        }
    }
}
